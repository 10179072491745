import React from "react";
import { graphql } from "gatsby";

import CustomHead from "../../components/common/Head";
import PurchaseCompleteTemplate from "../../templates/purchaseComplete";

// Prismic UID is the same as the Firestore ID
export const query = graphql`
  query SpecificProjectByID($uid: String) {
    prismicProject(uid: { eq: $uid }) {
      uid
      data {
        short_name {
          text
        }
        price {
          currency
          number
        }
        status
        isprojectfree
        payment_link
        free_download_link
        body1 {
          ... on PrismicProjectDataBody1WhatSIncluded {
            primary {
              assets_preview {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholderImgixParams: { blur: 10 }
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
    prismicPurchaseComplete {
      data {
        next_steps_heading
        next_steps {
          step
        }
        next_steps_pre_sale {
          step
        }
        additional_info {
          richText
        }
      }
    }
  }
`;

const PurchaseCompletePageWithData = ({ data, location, params }) => {
  return (
    <PurchaseCompleteTemplate data={data} location={location} params={params} />
  );
};

export const Head = ({ data }) => {
  console.log("DATA", data);
  const pageMeta = {
    title: "Purchase complete",
    description:
      "Thank you for your purchase and congrats on taking this extra step in your learning journey.",
  };

  return <CustomHead pageMeta={pageMeta} />;
};

export default PurchaseCompletePageWithData;
