import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { AuthContext } from "../context/authContext";

import { updateUserProducts } from "../api/users/userApi";

import Section from "../components/common/Section";
import Heading from "../components/common/Heading";
import Image from "../components/common/Image";
import Arrow from "../components/common/Arrow";
import RichText from "../components/common/RichText";
import List from "../components/common/List";
import PrivateRoute from "../components/common/PrivateRoute";

function PurchaseComplete({ data, location, params }) {
  const { user, updateUser } = useContext(AuthContext);

  const { uid: projectId } = params;
  const { data: project } = data?.prismicProject;
  const { data: instructions } = data?.prismicPurchaseComplete;

  // const [transactionDetails, setTransactionDetails] = useState({});
  // const { checkoutId, productId } = transactionDetails;
  useEffect(() => {
    // TODO decide whether to keep this
    const stripeParams = new URLSearchParams(location.search);
    const paramsObj = {};
    stripeParams.forEach((key, value) => {
      paramsObj[value] = key;
      setTransactionDetails(paramsObj);
    });
  }, [location.search]);

  const isFree = project.isprojectfree;

  // Add purchased product data to user's products[]
  // handled by backend for Stripe products
  // triggered here for SendOwl (free) products
  const [productAdded, setProductAdded] = useState(false);

  useEffect(() => {
    // if product was free and came from sendOwl,
    // and the user is logged in,
    // then add product to user in firestore
    if (isFree && user && !productAdded) {
      const payload = {
        projectId,
        email: user.email, // TODO Note user may have entered different email in SendOwl form... can we access this via SendOwl API?
        paymentIntentId: null,
        amount: 0,
        currency: null,
        created: Date.now(),
        country: null,
      };

      updateUserProducts(user.id, payload)
        .then(() => {
          setProductAdded(true);
          updateUser();
        })
        .catch((error) => {
          setProductAdded(false);
        });
    }
  }, [isFree, projectId, user, productAdded]);

  return (
    <PrivateRoute>
      <Section
        type="secondary"
        title={
          project.status === "pre_sale"
            ? "It's on its way"
            : "Get that coding hat on"
        }
      >
        {project && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
            <div className="">
              {isFree ? (
                <Heading className="mb-6">Hurray!</Heading>
              ) : (
                <Heading className="mb-6">Thank you for your purchase!</Heading>
              )}
              {project.status === "pre_sale" ? (
                <Heading className="mb-10">
                  The {project.short_name.text} project will soon be available
                  for you to build.
                </Heading>
              ) : (
                <Heading className="mb-10">
                  The {project.short_name.text} project is now available for you
                  to build.
                </Heading>
              )}

              {project.body1[0] && (
                <Image
                  image={project.body1[0].primary.assets_preview}
                  alt="illustration of what is included in your purchase, such as design, style guide and all assets needed for your coding"
                  className="max-w-md"
                />
              )}
            </div>
            <div className="">
              <Heading className="mb-6">
                {instructions.next_steps_heading}
              </Heading>
              {project.status === "pre_sale" ? (
                <List
                  items={instructions.next_steps_pre_sale}
                  itemKey="step"
                  numbered
                  className="mb-10"
                />
              ) : (
                <List
                  items={instructions.next_steps}
                  itemKey="step"
                  numbered
                  className="mb-10"
                />
              )}
              <RichText field={instructions.additional_info.richText} />
              <Arrow className="w-40 mt-10 -rotate-90" />
            </div>
          </div>
        )}
      </Section>
    </PrivateRoute>
  );
}

PurchaseComplete.propTypes = {
  data: PropTypes.shape({
    uid: PropTypes.string, // Prismic UID must be the same as the Firestore ID
    data: PropTypes.shape({
      short_name: PropTypes.shape({ text: PropTypes.string }),
    }),
  }),
};

export default PurchaseComplete;
