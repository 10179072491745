import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { AuthContext } from "../../context/authContext";
import Layout from "./Layout";
import Section from "./Section";
import Paragraph from "./Paragraph";
import GithubLoginButton from "../auth/GithubLoginButton";
import Shape from "./Shape";
import Button from "./Button";

function PrivateRoute({ children }) {
  const { isLoggedIn, loading } = useContext(AuthContext);

  if (loading) {
    return (
      <Layout>
        <Section loading />
      </Layout>
    );
  }

  return (
    <Layout>
      {isLoggedIn ? (
        children
      ) : (
        <Section title="Please log in">
          <Paragraph className="mb-10">
            You need to be logged in to access this page.
          </Paragraph>
          <GithubLoginButton />
          <Button size="lg" className="mt-10" variant="secondary">
            <Link to="/">Go back to the homepage</Link>
          </Button>
          <div className="w-1/3 ml-auto">
            <Shape type="bigD" className="text-eucalyptus -rotate-90" />
          </div>
        </Section>
      )}
    </Layout>
  );
}

export default PrivateRoute;

PrivateRoute.propTypes = {
  children: PropTypes.node,
};
